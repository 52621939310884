<template>
  <div class="tableData" style="font-size: 10px;">
    <table border="1" rules="all">
      <tr>
        <th>权限</th>
        <th>权限名</th>
        <th>功能描述</th>
        <th>可访问的个人信息</th>
        <th>业务功能场景</th>
        <th>是否可关闭</th>
      </tr>
      <tr>
        <td>ACCESS_BACKGROUND_LOCATION</td>
        <td>支持后台访问位置</td>
        <td>允许App在后台运行时使用位置信息（需要 App 获得访问粗略位置或访问精准位置权限）</td>
        <td>实时地理位置信息、行踪轨迹</td>
        <td>当用户在分身应用中使用地图导航、网络约车、运动健身等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>ACCESS_COARSE_LOCATION</td>
        <td>访问粗略位置</td>
        <td>允许App获取基于基站、IP 等粗略的地理位置</td>
        <td>粗略地理位置信息</td>
        <td>当用户在分身应用中获取本地区域信息推荐、地域新闻推送等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>ACCESS_FINE_LOCATION</td>
        <td>访问精准定位</td>
        <td>允许App获取基于GPS 等的精准地理位置</td>
        <td>精准地理位置信息</td>
        <td>当用户在分身应用中记录照片拍摄位置、社交分享位置等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>ACTIVITY_RECOGNITION</td>
        <td>识别身体活动</td>
        <td>允许App识别身体活动</td>
        <td>特定身体活动变化信息（如未移动、步行、跑步、骑行等）</td>
        <td>在分身应用中追踪步数、记录运动消耗等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>BLUETOOTH_ADMIN</td>
        <td>打开蓝牙</td>
        <td>允许App打开或关闭蓝牙</td>
        <td>蓝牙上传/下载信息</td>
        <td>当用户使用蓝牙传输功能时</td>
        <td>是</td>
      </tr>
      <tr>
        <td>BODY_SENSORS</td>
        <td>身体传感器信息</td>
        <td>允许App访问身体内部状况相关的传感器数据</td>
        <td>身体传感器数据</td>
        <td>在扫一扫场景下通过光线传感器可判断黑暗环境、播放视频时通过重力传感器适配横竖屏、摇一摇互动场景下识别用户动作、运动时通过身体传感器获取心率状况等</td>
        <td>是</td>
      </tr>
      <tr>
        <td>CALL_PHONE</td>
        <td>拨打电话</td>
        <td>允许App直接拨打电话</td>
        <td>实时通话行为</td>
        <td>在分身应用中内直接拨打商家、快递员、客服电话等</td>
        <td>是</td>
      </tr>
      <tr>
        <td>CAMERA</td>
        <td>拍摄</td>
        <td>允许App使用摄像头</td>
        <td>照片或视频信息</td>
        <td>拍摄照片/视频、扫描二维码/条形码、人脸识别等功能</td>
        <td>是</td>
      </tr>
      <tr>
        <td>CHANGE_WIFI_STATE</td>
        <td>改变WLAN状态的开关</td>
        <td>允许App打开或关闭Wi-Fi</td>
        <td>网络配置信息</td>
        <td>当用户需要更新/下载内容、媒体文件、应用组件、升级包等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>GET_ACCOUNTS</td>
        <td>获取App账户</td>
        <td>允许App从账户务中获取 App 账户列表</td>
        <td>账户服务中的 App 账户列表</td>
        <td>账号登录场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>GET_INSTALLED_APPS</td>
        <td>获取当前已安装应用软件列表</td>
        <td>允许App获取当前已安装应用软件列表</td>
        <td>已安装应用软件列表</td>
        <td>当用户需要依赖本机已安装应用软件列表来选择并创建分身时</td>
        <td>是</td>
      </tr>
      <tr>
        <td>GET_TASKS</td>
        <td>获取当前或最近运行的应用</td>
        <td>允许App获取当前或最近运行的应用</td>
        <td>当前或最近运行的应用</td>
        <td>用于判断分身应用程序进程是否处于正常运行状态</td>
        <td>是</td>
      </tr>
      <tr>
        <td>PACKAGE_USAGE_STATS</td>
        <td>读取应用使用情况</td>
        <td>允许App获取其他App的使用统计数据</td>
        <td>其他App的使用统计数据</td>
        <td>用于统计分身应用的使用频率、使用时长、语言设置、使用记录信息</td>
        <td>是</td>
      </tr>
      <tr>
        <td>READ_CALENDAR</td>
        <td>读取日历</td>
        <td>允许App读取用户日历数据</td>
        <td>系统日历中的日程安排信息</td>
        <td>在分身应用中使用日程规划、事件提醒、票务预订等功能</td>
        <td>是</td>
      </tr>
      <tr>
        <td>READ_CONTACTS</td>
        <td>读取通讯录</td>
        <td>允许App读取用户通讯录</td>
        <td>联系人数据</td>
        <td>在分身应用中使用通讯录管理与备份、添加联系人等功能</td>
        <td>是</td>
      </tr>
      <tr>
        <td>READ_EXTERNAL_STORAGE</td>
        <td>读取外置存储器</td>
        <td>允许App读取外置存储器</td>
        <td>外置存储器存储的个人数据</td>
        <td>在分身应用中使用文件管理等需要打开本地文件的场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>READ_PHONE_STATE</td>
        <td>读取电话状态</td>
        <td>获取设备唯一标识信息（如IMSI、IMEI、设备序列号）以及手机通话状态</td>
        <td>设备唯一标识信息（如IMSI、IMEI、设备序列号）</td>
        <td>进行用户常用设备的标识，可用于监测App账户异常登录、关联用户行为</td>
        <td>是</td>
      </tr>
      <tr>
        <td>RECORD_AUDIO</td>
        <td>录音</td>
        <td>允许App使用麦克风进行录音</td>
        <td>录音内容</td>
        <td>当用户在分身应用中使用语音即时通信、语音识别、音视频录制、直播等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>REQUEST_INSTALL_PACKAGES</td>
        <td>安装未知应用</td>
        <td>允许在App内安装其他应用</td>
        <td>无</td>
        <td>当用户需要安装兼容性更佳的分身应用或版本升级等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>SYSTEM_ALERT_WINDOW</td>
        <td>悬浮窗</td>
        <td>允许App在其他App上覆盖显示</td>
        <td>无</td>
        <td>当用户需要开启画中画功能、用小窗播放视频/直播等场景</td>
        <td>是</td>
      </tr>
      <tr>
        <td>USE_SIP</td>
        <td>使用网络电话</td>
        <td>允许App拨打/接听SIP网络电话</td>
        <td>实时网络通话行为</td>
        <td>当用户在分身应用中接听、拨打网络电话</td>
        <td>是</td>
      </tr>
      <tr>
        <td>WRITE_CALENDAR</td>
        <td>编辑日历</td>
        <td>允许App写入用户日历数据</td>
        <td>系统日历中的日程安排信息</td>
        <td>当用户在分身应用中使用日程规划、事件提醒、票务预订等功能</td>
        <td>是</td>
      </tr>
      <tr>
        <td>WRITE_CONTACTS</td>
        <td>编辑通讯录</td>
        <td>允许App写入用户通讯录</td>
        <td>联系人数据</td>
        <td>当用户在分身应用中使用通讯录管理与备份、添加联系人等功能</td>
        <td>是</td>
      </tr>
      <tr>
        <td>WRITE_EXTERNAL_STORAGE</td>
        <td>写入外置存储器</td>
        <td>允许App写入外置存储器</td>
        <td>外置存储器存储的个人数据</td>
        <td>在分身应用中使用文件管理等需要打开本地文件的场景</td>
        <td>是</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  components: { },
  props: {},
  data() {
    return {
      isLoading: true
    };
  },
  watch: {},
  computed: {},
  methods: {
  },
  created() {
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    background: no-repeat 0 0;
    background-size: 100% auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    height: auto;
    -webkit-user-select: none;
    user-select: none;
    color: #333;
    background-color: #ffffff;
  }
  li {
    list-style: none;
    padding: 0.5em 0;
    width: 100%;
  }
  .tableData {
    width: 100%;
    overflow: auto;
  }
  table {
    border-collapse: collapse;
    width: 600px;
  }
  table,
  table tr th,
  table tr td {
    white-space: nowrap;
  }
  th,
  td {
    padding: 0.5em;
  }
  #app {
    font-family: -apple-system-font, BlinkMacSystemFont, "Helvetica Neue",
    "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei",
    Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 0.8em;
  }
  .right {
    text-align: right;
  }
  .middle {
    text-align: center;
  }
  p {
    line-height: 1.6em;
    word-break: break-all;
    padding: 0.4em 0;
  }
  h3,
  h4 {
    padding: 0.6em 0;
    margin-bottom: 0.5em;
  }
  .layout {
    padding: 1em 4%;
    letter-spacing: 1px;
  }
  .title {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 2em;
    text-align: center;
  }
  .sub_list > * {
    padding: 0.6em 0;
  }
</style>